import React from 'react';
import ReactDOM from 'react-dom';
import type { Nullable } from '@smd/utilities';
import { getMeridianDataLayerOrNull, type MeridianDataLayerValue } from '@smd/datalayer-typings';
import { AsyncEffect, log } from '../utils';
import * as DataLayer from '../DataLayer';
import { PageMeta } from '../PageMeta';
import type { AdType } from '../AdType';
import { Context } from './Context';
import { from } from './from';
import type { Config } from '.';

export function use({
	endpoint,
	intermingleCount,
	columnsCount = 2,
	dehydratedConfig,
	supportedAdTypes,
	getMeridianDataLayer = getMeridianDataLayerOrNull,
}: use.Options) {
	const [dataLayer, refreshDataLayer] = DataLayer.use(getMeridianDataLayer);
	const [config, setConfig] = React.useState<Config>(Context.defaultValue);
	const dehydratedConfigRef = React.useRef(dehydratedConfig);

	const refresh = React.useCallback(() => {
		log('REQUEST', 'API', 'AdRequest', 'Refreshing config');
		setConfig(Context.emptyValue);
		refreshDataLayer();
	}, [refreshDataLayer, setConfig]);

	AsyncEffect.use(() => {
		if (!dataLayer) return;

		if (typeof intermingleCount !== 'number') {
			log.error('REQUEST', 'API', 'AdRequest', 'No intermingleCount set, aborting...');
			return;
		}

		if (dehydratedConfigRef.current) {
			const { current: dehydratedConfig } = dehydratedConfigRef;
			dehydratedConfigRef.current = null;

			log('REQUEST', 'API', 'AdRequest', 'Using dehydrated config', { dehydratedConfig });
			setConfig(dehydratedConfig);

			return;
		}

		return {
			effect: async ({ abortSignal }) => {
				try {
					const options = {
						abortSignal,
						endpoint,
						dataLayer,
						supportedAdTypes,
						intermingleCount,
						columnsCount,
					} as const satisfies from.Options;

					log('REQUEST', 'API', 'AdRequest', 'Requesting config', { options });

					const config = await from(options);

					if (abortSignal.aborted) {
						log.warn(
							'REQUEST',
							'API',
							'AdRequest',
							'Received config but cleanup ran, aborting...',
							{ config },
						);
						return;
					}

					log('REQUEST', 'API', 'AdRequest', 'Setting config', { config });

					// Flush the state update to get the new config into the context
					// synchronously, so that ad slots have a chance to complete rendering
					// before we start requesting ads for them:
					ReactDOM.flushSync(() => setConfig(config));
				} catch (error) {
					if (abortSignal.aborted) {
						log.warn('REQUEST', 'API', 'AdRequest', 'Cleanup ran, aborting...', { error });
					} else {
						log.error('REQUEST', 'API', 'AdRequest', 'Failed', { error });
					}
				}
			},
		};
	}, [dataLayer]);

	const pageMeta = PageMeta.use(dataLayer);

	return [config, refresh, pageMeta] as const;
}

export namespace use {
	export type Options = {
		/** The endpoint to use for fetching advertising configuration. */
		endpoint: string;

		/** The total number of listings on the page where an intermingle ad can be displayed. */
		intermingleCount: Nullable<number>;

		/** The number of columns in the listing grid. */
		columnsCount?: number;

		/** The ad types that are supported by the page. */
		supportedAdTypes: Array<AdType.Supported>;

		/** A function that returns the dataLayer. */
		getMeridianDataLayer?(): Nullable<MeridianDataLayerValue>;

		/** The configuration to use for hydration. */
		dehydratedConfig?: Nullable<Config>;
	};
}
