// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../lib/prebid/index.d.ts" />

import { as, ensure, type Nullable, PromiseWithResolvers } from '@smd/utilities';
import { hasGoogleConsent } from '@smd/cmp-sourcepoint';
import * as Core from '../../core';
import * as namespace from './.namespace';

class ApiLoader extends Core.ApiLoader<Api> {
	override readonly globalKey = 'relevantDigital';
	protected override readonly namespace = namespace;

	protected override get scriptUrl() {
		if (!this.#scriptUrl) throw new ReferenceError('Relevant Yield HBM script URL not set!');
		return this.#scriptUrl;
	}

	#scriptUrl?: Core.ApiLoader.ScriptUrl;
	protected override readonly hasScriptUrl = PromiseWithResolvers.of();

	setScriptUrl(provider: ApiLoader.ScriptUrl.Provider) {
		this.#scriptUrl = provider(ApiLoader.ScriptUrl);
		this.hasScriptUrl.resolve();
	}

	get pbjs() {
		return this.execute(function () {
			return this.getInstance().pbjs;
		});
	}

	override async execute<R>(
		executor: Core.ApiLoader.Executor<Api, R>,
		...abortSignals: ReadonlyArray<Nullable<AbortSignal>>
	) {
		for (const abortSignal of abortSignals) abortSignal?.throwIfAborted();

		const globalRef = await this.load();
		const { promise, resolve, reject } = PromiseWithResolvers.of<R>();

		globalRef.cmd.push(() => {
			try {
				for (const abortSignal of abortSignals) abortSignal?.throwIfAborted();
				resolve(executor.apply(globalRef, [...abortSignals]));
			} catch (error) {
				reject(error);
			}
		});

		return await promise;
	}

	protected override async resolveReference() {
		const globalRef = ensure(window[this.globalKey]);
		const { promise, resolve, reject } = PromiseWithResolvers.of<Api>();

		try {
			globalRef.cmd.push(() => resolve(globalRef));
		} catch (error) {
			reject(error);
		}

		return await promise;
	}

	protected override async shouldLoad() {
		await this.hasScriptUrl.promise;
		return await hasGoogleConsent();
	}

	protected override async prepare() {
		const globalRef = (window[this.globalKey] ??= as({}));
		(globalRef.cmd as typeof globalRef.cmd | undefined) ??= new Array();

		return Promise.resolve();
	}
}

namespace ApiLoader {
	export namespace ScriptUrl {
		export function fromTagId<T extends string>(tagId: T) {
			return `https://schibsteddk-cdn.relevant-digital.com/static/tags/${tagId}.js` as const satisfies Core.ApiLoader.ScriptUrl;
		}

		export function fromPageMeta({ brand, platform }: Core.PageMeta) {
			return ScriptUrl[brand][platform];
		}

		export namespace dba {
			export const desktop = fromTagId('6583e88dbd64941426aa1034');
			export const web = fromTagId('66daea85ea8a5f9564d5da21');
		}

		export namespace bilbasen {
			export const desktop = fromTagId('6583e88dbd64941426aa1312');
			export const web = fromTagId('66d71d747685d88c764de9da');
		}

		export type Provider = (ScriptUrl: typeof ApiLoader.ScriptUrl) => Core.ApiLoader.ScriptUrl;
	}
}

export const Api = new ApiLoader();

/** @see {@link https://help.relevant-digital.com/knowledge/overview-javascript-api} */
export type Api = {
	readonly cmd: Array<VoidFunction>;

	/**
	 * This is the fundamental function for loading ads with HB Manager. For some customers this will be the only function ever used in the API. Please read this documentation first as it also contains basic examples on usage of HB Manager.
	 * @see {@link https://help.relevant-digital.com/knowledge/relevantdigital.loadprebid}
	 */
	loadPrebid(parameters: Api.LoadPrebid.Parameters): void;

	/** Use instead of `pbjs.setConfig` */
	addPrebidConfig(config: Pbjs.Config): void;

	/**
	 * Used for setting up global callbacks - as an alternative to providing them to {@linkcode Api.loadPrebid}.
	 * @see https://help.relevant-digital.com/knowledge/relevantdigital.addauctioncallbacks
	 */
	addAuctionCallbacks(): void;

	/**
	 * This function lists the Prebid configurations in the site.
	 *
	 * The prebid configurations objects contains the placements created in Relevant Yield for each "Prebid configuration" created for the site.
	 * @example
	 * window.relevantDigital = window.relevantDigital || {};
	 * relevantDigital.cmd = relevantDigital.cmd || [];
	 * relevantDigital.cmd.push(function() {
	 * 	relevantDigital.getConfigs().forEach((cfg) => {
	 * 		console.info('name:', cfg.name);
	 * 		console.info('configId: ', cfg.configId);
	 * 		console.info('Relevant Yield placements', cfg.adUnits);
	 * 	});
	 * });
	 * @see https://help.relevant-digital.com/knowledge/relevantdigital.getconfigs
	 */
	getConfigs(): ReadonlyArray<Api.Config>;

	/**
	 * getInstance() returns the internal root object of the Relevant Yield environment.
	 *
	 * **WARNING:** Please *only* use the fields listed below, as other fields and methods might change/disappear *without prior notice*.
	 *
	 * ### Supported fields
	 * - `siteData` - The Generic site data object for the site. The content depends on the settings in Generic site data set in Prebid parameters for the site.
	 * - `pbjs` - The global pbjs-variable used by Yield. Use this instead of `windows.pbjs` if you want the possibility to change the global pbjs-variable name in the prebid build to something else later, without having to update your code.
	 * @example
	 * window.relevantDigital = window.relevantDigital || {};
	 * relevantDigital.cmd = relevantDigital.cmd || [];
	 * relevantDigital.cmd.push(() => {
	 * 	const { siteData } = relevantDigital.getInstance();
	 * 	console.info('Some setting:', siteData.someCustomSetting);
	 * });
	 * @see https://help.relevant-digital.com/knowledge/relevantdigital.getinstance
	 */
	getInstance(): Api.Instance;

	/**
	 * This function is used for obtaining placement-information such as the ad slot from a Prebid.js bid object.
	 *
	 * By using this function it's possible match a bid object obtained from e.g. a pbjs.onEvent() event handler to the right placement.It will return an object that contain, most importantly, a .slot member representing the ad slot.
	 *
	 * When using GAM this object might be a googletag.Slot object, but when using instream video, delayedAdserverLoading or another ad server - it will instead be an own type with a similar (but much smaller) API. In particular, the slot object will always contain the following functions:
	 *
	 * - `slot.getSlotElementId()` - Will return the div-id of the ad slot, or in the case of instream video - the `id` supplied to `relevantDigital.defineVideoSlots()`.
	 * - `slot.getAdUnitPath()` - The ad unit path / placement id.
	 *
	 * ### Parameters
	 * - `bid` - the bid object obtained from Prebid.js. For example via `pbjs.onEvent()` or `pbjs.getBidResponses()`.
	 * @example
	 * pbjs.onEvent('bidWon', (bid) => {
	 * 	const instance = relevantDigital.getAdUnitInstanceByBid(bid);
	 * 	const { slot } = instance;
	 * 	console.info('div id: ', slot.getSlotElementId());
	 * 	console.info('placement id: ', slot.getAdUnitPath());
	 * });
	 */
	getAdUnitInstanceByBid(bid: Pbjs.Bid.Args.Common): Api.Auction.UsedUnitData;

	/**
	 * Function to be used in Single Page Applications (SPA) when navigating between content.
	 *
	 * The `destroySlots()` function is designed to be used on SPA pages (React, etc) - in between page views. It will reset some internal state in the Relevant Yield script and reset ad slots in the ad server. The latter includes:
	 * - For **Google Ad Manager** - `googletag.destroySlots()` will be called.
	 * - For **Xandr ad server** - `apntag.clearRequests()` will be called.
	 * @example
	 * // No need to use relevantDigital.cmd.push()
	 * // as we only need to call the function
	 * // If the library is indeed initialized.
	 *
	 * if (window.relevantDigital && relevantDigital.destroySlots) {
	 * 	relevantDigital.destroySlots();
	 * }
	 */
	destroySlots(): void;
};

export namespace Api {
	export type Instance = Readonly<{
		/** The Generic site data object for the site. The content depends on the settings in Generic site data set in Prebid parameters for the site. */
		siteData: unknown;

		/** The global pbjs-variable used by Yield. Use this instead of `windows.pbjs` if you want the possibility to change the global pbjs-variable name in the prebid build to something else later, without having to update your code. */
		pbjs: Pbjs;
	}>;

	export type Config = Config.Parent | Config.Child;

	export namespace Config {
		export type Common = Readonly<{
			adUnits: ReadonlyArray<unknown>;
			allAdsIds: ReadonlyArray<string>;
			bannerAdsIds: ReadonlyArray<string>;
			configId: string;
			data: Config.Common.Data;
			hbaSiteId: string;
			instreamAdsIds: ReadonlyArray<string>;
			name: string;
			placementTypesById: unknown;
			userSync: unknown;
		}>;

		export namespace Common {
			export type Data = Readonly<
				Partial<Record<string, unknown>> & {
					configurationType: Data.ConfigurationType;
					priority: Data.Priority;
				}
			>;

			export namespace Data {
				export type ConfigurationType = 'consented' | 'nonconsented' | null;
				export type Priority = number | null;
			}
		}

		export type Parent = Common &
			Readonly<{
				abTestLocalStorage: boolean;
			}>;

		export type Child = Common &
			Readonly<{
				country: ReadonlyArray<unknown>;
				enabled: boolean;
				parentConfigId: string;
				percentage: number;
			}>;
	}

	export namespace LoadPrebid {
		export type Parameters = {
			/**
			 * The `configId` string of the Prebid Configuration created in Yield. To list available prebid configurations you can use the `relevantDigital.getConfigs()` function. This parameter is mandatory.
			 */
			configId: string;

			/** If `true`, then slots that have already been loaded won't be reloaded. This parameter should be set in lazy-load and infinite-scroll scenarios. In fact it might be used in all cases except when you explicitly want ads to reload. */
			noSlotReload: boolean;

			/**
			 * An array of div-ids for ad slots that should be loaded. Notice that this will act as a filter for which ad slots on the page that should be loaded.
			 * - Note: This does not apply to the div attribute `"data-ad-unit-id"` or any other `div` attribute bar the `id`.
			 * @example
			 * relevantDigital.loadPrebid({ ..., allowedDivIds: ['top-banner', 'interstitial'] });
			 */
			allowedDivIds: null | ReadonlyArray<string>;

			/**
			 * A string - `"instream"` or `"banner"`. Used for loading only instream placements or only non-instream (normal) placements. This can for example be used if you don't want to combine instream placements with the rest of the placements in the prebid auction. If you want to create a different auction for instream placements that will run after the normal auction you can use this pattern:
			 * ```js
			 * relevantDigital.loadPrebid({ ..., allowedPlacementType: 'banner' });
			 * relevantDigital.loadPrebid({ ..., allowedPlacementType: 'instream' });
			 * ```
			 */
			allowedPlacementType?: 'instream' | 'banner';

			/**
			 * It is recommended to always set this to `true`. For legacy reasons our script will load Google's tag script `gpt.js` if you're using Google Ad Manager and this parameter is not `true`. However, for performance reasons it's usually better to load this script directly in `<head>` on the page instead.
			 */
			noGpt: boolean;

			/**
			 * For Google Ad Manager - a boolean that, when set to `true`, makes it possible to run the Prebid auction before loading of the GAM script (gpt.js) is completed. When using this setting the `slot` objects in callbacks etc will not be a `googletag.Slot` instance. Instead it will be an own type. In order to access the actual `googletag.Slot` object one must wait for it to become available via the `slot.waitGamSlot()` function.
			 * @example
			 * relevantDigital.loadPrebid({
			 *    ...
			 *    delayedAdserverLoading: true,
			 *    onSlotAndUnit: ({ slot, unit }) => {
			 *       slot.waitGamSlot((gamSlot) => {
			 *          gamSlot.setTargeting("some", "targeting");
			 *       });
			 *    },
			 * };
			 */
			delayedAdserverLoading?: boolean;

			/**
			 * For Google Ad Manager the first ad request will by default be done by a call to `googletag.pubads.refresh()` without any array of slots in order to request all existing ad slots - not only the ones in the first auction. Set this parameter to `true` to only include slots that are part of the first auction in the first ad request (this is always the case for subsequent auctions anyway).
			 */
			noAdsInitRequestAll?: boolean;

			/**
			 * For Google Ad Manger and Xandr Ad server. Normally only slots included in the current prebid configuration is loaded - except for the first auction with Google when not using the `noAdsInitRequestAll` flag above. Setting this parameter to `true` will cause "unknown" ad slots to be loaded as well. Notice that the `noSlotReload` and `allowedDivIds` parameters will work as normal for these unknown slots.
			 */
			loadUnknownSlots?: boolean;

			/**
			 * Set to `true` only if there are multiple placements in the same Prebid configuration that is sharing the same ad unit path in the placement ad server settings in Yield. This is a special case that also requires usage of an `onSlotAndUnit` callback function that returns `false` for some placements, as otherwise all slots will always be matched to the first placement in Yield.
			 *
			 * WARNING: This is absolutely not the recommended way to handle shared ad unit paths in Google Ad Manger, instead when sharing ad unit paths it's recommended to to use Custom targeting key appended to ad unit path and ID of custom targeting key to append ad unit path settings in the global ad server settings in Yield. Please contact Relevant support for more details.
			 */
			hasSharedAdUnits?: boolean;

			/**
			 * Callback function for customizing the ad unit codes in Prebid.js. Notice that this code might change anyway as we're never re-using the same ad unit code.
			 * @example
			 * relevantDigital.loadPrebid({
			 *     ...
			 *     createAdUnitCode: ({ code, unit, slot }) => {
			 *        if(slot.getSlotElementId() === 'top-banner') {
			 *           return 'my-top-banner-ad-unit-code';
			 *        }
			 *        return null; // use the normal generated ad unit code
			 *     },
			 * };
			 */
			createAdUnitCode?(parameters: CreateAdUnitCode.Parameters): string | null;

			/**
			 * Callback function that will be invoked before starting an auction.
			 * @example
			 * relevantDigital.loadPrebid({
			 *    ...
			 *    onBeforeAuctionSetup: ({ auction }) => {
			 *        console.info('Relevant Yield auction object', auction);
			 *    },
			 * };
			 * @description
			 * The callback will be called when all previous auctions are done (ad requests initiated) - but before the the check for available ad-slots. Therefore you can safely create ad-slots at this point that will be included in the auction (manually or by setting `data-ad-unit-id` on `div`-elements when using `manageAdserver`).
			 */
			onBeforeAuctionSetup?(parameters: OnBeforeAuctionSetup.Parameters): void;

			/**
			 * Callback function that will be invoked for every ad slot that will participate in the auction.  Unless the callback function returns `false` (exactly) - as then the ad slot will be skipped.
			 * @example
			 * relevantDigital.loadPrebid({
			 *     ...
			 *     onSlotAndUnit: ({ slot, unit, requestAuction }) => {
			 *         console.info('Ad Slot', slot);
			 *         console.info('Prebid.JS ad unit', unit.pbAdUnit);
			 *         console.info('Relevant Yield placement', unit);
			 *         console.info('Relevant Yield auction object', auction);
			 *     },
			 * };
			 * @description
			 * One use-case of this callback is to do custom adjustments to the Prebid.js bid parameters, available in `unit.pbAdUnit.bids[]`.
			 *
			 * Notice that `unit` represents one placement added in Relevant Yield. If you load the same placement multiple times in the same `loadPrebid()` call - then multiple callbacks with the same `unit` will occur.
			 *
			 * Special case: use multiple Yield placements for the same slot. This is possible by returning `{reUseSlot: true}`. However, this requires us to, for each slot - remove all except one ad unit instance before making the ad request.
			 * @example
			 * relevantDigital.loadPrebid({
			 *    onSlotAndUnit: ({ slot, unit }) => {
			 *         return { reUseSlot: true };
			 *    },
			 *    onBeforeAdRequest: ({ auction }) => {
			 *       const bySlot = new Map();
			 *       auction.usedUnitDatas.forEach((unitData) => {
			 *          bySlot[unitData.slot] = bySlot[unitData.slot] || [];
			 *          bySlot[unitData.slot].push(unitData);
			 *       });
			 *       // Call .remove() on all ad unit instances except the one, per slot,
			 *       // that has the highest CPM bid.
			 *       const cpmOf = (unitData) => unitData.getHighestBid()?.cpm || 0;
			 *       Object.values(bySlot).forEach((arr) => {
			 *          arr.sort((a, b) => (
			 *             cpmOf(a) === cpmOf(b) ? 0 : (cpmOf(a) < cpmOf(b) ? -1 : 1)
			 *          ));
			 *          arr.slice(1).forEach((unitData) => unitData.remove());
			 *       });
			 *    },
			 * })
			 */
			onSlotAndUnit?(parameters: OnSlotAndUnit.Parameters): void | false | { reUseSlot: true };

			/**
			 * Callback function invoked after all placements has been set up and before the Prebid.JS auction (called after `onSlotAndUnit`).
			 * @example
			 * relevantDigital.loadPrebid({
			 *    ...
			 *    onAuctionInitDone: ({ auction }) => {
			 *        console.info('Relevant Yield auction object', auction);
			 *        auction.usedUnitDatas.forEach((unitData) => {
			 *           const { pbAdUnit, adUnit, slot, code } = unitData;
			 *           console.info('Ad Slot', slot);
			 *           console.info('Prebid.JS ad unit', pbAdUnit);
			 *           console.info('Prebid.JS ad unit code', code);
			 *           console.info('Relevant Yield placement', adUnit);
			 *        });
			 *    },
			 * };
			 */
			onAuctionInitDone?(parameters: OnAuctionInitDone.Parameters): void;

			/**
			 * Callback function invoked after the Prebid auction is done and immediately before the ad request is initiated.
			 * @example
			 * relevantDigital.loadPrebid({
			 *    ...
			 *    onBeforeAdRequest: ({ auction }) => {
			 *       console.info('Relevant Yield auction object', auction);
			 *    },
			 * };
			 */
			onBeforeAdRequest?(parameters: OnBeforeAdRequest.Parameters): void;

			/**
			 * Callback function invoked after the Prebid auction is done and immediately after the ad request has been initiated.
			 * @example
			 * relevantDigital.loadPrebid({
			 *    ...
			 *    onAuctionDone: ({ auction }) => {
			 *       console.info('Relevant Yield auction object', auction);
			 *    },
			 * };
			 */
			onAuctionDone?(parameters: OnAuctionDone.Parameters): void;

			/**
			 * Callback function invoked for every Amazon slot - when using Amazon UAM/TAM in parallel with Prebid. This callback can be used for "finalizing" this slot before the call to Amazon is done.
			 * @example
			 * relevantDigital.loadPrebid({
			 *    ...
			 *  onAmazonSlot: (amazonSlot, unitData) => {
			 *     console.info('Ad slot, placement in Yield, etc', unitData);
			 *     amazonSlot.slotParams.SOME_KEY = "SOME_VALUE"
			 *  },
			 * };
			 */
			onAmazonSlot?(amazonSlot: unknown, unitData: unknown): void;

			/**
			 * This object can be used to setup functions that will intercept the calls done by Relevant Yield to the `pbjs.??` functions. Currently these functions are used:
			 * - requestBids
			 * - addAdUnits
			 * - removeAdUnit
			 * - renderAd
			 * - setConfig
			 * - aliasBidder
			 * - onEvent
			 * - getBidResponsesForAdUnitCode
			 * - getHighestCpmBids
			 * - setTargetingForGPTAsync
			 * - setTargetingForAst
			 *
			 * The callback functions will get the Relevant Yield auction objects in `this.auction`.
			 * @example
			 * relevantDigital.loadPrebid({
			 *    ...
			 *   pbjsCalls: {
			 *     requestBids: function(requestObj) {
			 *         console.info('Relevant Yield auction object', this.auction);
			 *         return pbjs.requestBids(requestObj); // Do the actual call
			 *     },
			 *   },
			 * };
			 */
			pbjsCalls?: PbjsCalls;

			/**
			 * For usage with Google Ad Manager only. This object can be used to setup functions that will intercept the calls done by Relevant Yield to the `googletag.??` and `googletag.pubads().??` functions. Currently these functions are used:
			 * - addEventListener
			 * - removeEventListener
			 * - getSlots
			 * - defineSlot
			 * - disableInitialLoad
			 * - enableSingleRequest
			 * - enableServices
			 * - refresh
			 *
			 * The callback functions will get the Relevant Yield auction objects in `this.auction`.
			 * @example
			 * relevantDigital.loadPrebid({
			 *    ...
			 *     googletagCalls: {
			 *       defineSlot: function(adUnitPath, size, div) {
			 *          console.info('Relevant Yield auction object', this.auction);
			 *          return googletag.defineSlot(adUnitPath, size, div); // Do the actual call
			 *       },
			 *     },
			 * };
			 */
			googletagCalls?: GoogletagCalls;

			/**
			 * For usage with Xandr ad server only. This object can be used to setup functions that will intercept the calls done by Relevant Yield to the `apntag.??` functions. Currently the functions used are these:
			 * - defineTag
			 * - loadTags
			 * - showTag
			 * - refresh
			 * - setKeywords
			 * - getTag
			 * - modifyTag
			 *
			 * The callback functions will get the Relevant Yield auction objects in `this.auction`.
			 * @example
			 * relevantDigital.loadPrebid({
			 *    ...
			 *     apntagCalls: {
			 *         defineTag: function(tagData) {
			 *             console.info('Relevant Yield auction object', this.auction);
			 *             tagData.enableSafeFrame = true; // Modify something
			 *             return apntag.defineTag(tagData); // Do the actual call
			 *         },
			 *     },
			 * };
			 */
			apntagCalls?: ApntagCalls;
		} & (Parameters.Managed | Parameters.Unmanaged);

		export namespace Parameters {
			export type Managed = {
				/**
				 * If true then new ad-slots will be constructed for every `<div>` with `data-ad-unit-id` set to the id/path of an ad placement. In addition, "setup" of the ad server will occur:
				 * - For Google Ad Manager - googeltag.pubads().disableInitialLoad() and other necessary calls will be done.
				 * - For Xandr ad server - apntag.setPageOpts() will be called with the member id configured in the ad server settings in Yield.
				 */
				manageAdserver: true;

				/**
				 * When using Google Ad Manager and `manageAdServer: true` - setting this to `true` corresponds to setting the first parameter to `true` in the call to `googletag.Slot.setCollapseEmptyDiv`. This can also be done individually per placement by using the `data-collapse-empty-divs` attribute, example:
				 * ```html
				 * <div data-ad-unit-id="/12345/news/top_banner" data-collapse-empty-divs></div>
				 * ```
				 */
				collapseEmptyDivs?: boolean;

				/**
				 * When using `collapseEmptyDivs` (see above) setting this parameter to `true` corresponds to setting the second parameter to `true` in the call to `googletag.Slot.setCollapseEmptyDiv`.
				 *
				 * This can also be done individually per placement by using the `data-collapse-before-ad-fetch` attribute when combined with `data-collapse-empty-divs`, example:
				 * ```html
				 * <div
				 *     data-ad-unit-id="/12345/news/top_banner"
				 *     data-collapse-empty-divs
				 *     data-collapse-before-ad-fetch
				 * ></div>
				 * ```
				 */
				collapseBeforeAdFetch?: boolean;

				/**
				 * A string denoting the html attribute to use instead of the default `data-ad-unit-id` when specifying ad unit path/ids with `manageAdserver: true`.
				 */
				divAttribute?: string;

				/**
				 * Callback function for implementing a custom way to match a `<div data-ad-unit-id="??">` element to a placement in Yield when using `manageAdserver`. The callback will receive an array `adUnits` of all available Yield placements in the Prebid configuration to select from.
				 * @example
				 * relevantDigital.loadPrebid({
				 *     ...
				 *     divToAdUnit: ({ div, path, adUnits, auction, defaultFn }) => {
				 *        const example = div.getAttribute('data-example');
				 *        const unit = adUnits.find((unit) => unit.data.exampleField === example)
				 *        if (unit) {
				 *           return unit;
				 *        }
				 *        return defaultFn(); // use the normal procedure
				 *     },
				 * };
				 */
				divToAdUnit?(parameters: DivToAdUnit.Parameters): unknown;

				/**
				 * Callback when a `<div data-ad-unit-id="??">` element is referring to a path that can't be found in the prebid configuration.
				 * @example
				 * relevantDigital.loadPrebid({
				 *    ...
				 *    onAdUnitMatchingFailed: ({ div, auction, path, defaultFn }) => {
				 *       if (!isAnExpectedFailure(path)) { // Some kind of logic..
				 *              defaultFn(); // Default behavior (show console error)
				 *          }
				 *    },
				 * };
				 */
				onAdUnitMatchingFailed?(parameters: OnAdUnitMatchingFailed.Parameters): void;
			};

			export type Unmanaged = {
				/**
				 * If true then new ad-slots will be constructed for every `<div>` with `data-ad-unit-id` set to the id/path of an ad placement. In addition, "setup" of the ad server will occur:
				 * - For Google Ad Manager - googeltag.pubads().disableInitialLoad() and other necessary calls will be done.
				 * - For Xandr ad server - apntag.setPageOpts() will be called with the member id configured in the ad server settings in Yield.
				 */
				manageAdserver: false;
			};
		}

		export namespace CreateAdUnitCode {
			export type Parameters = Readonly<{
				code: string;
				unit: unknown;
				slot: googletag.Slot;
			}>;
		}

		export namespace DivToAdUnit {
			export type Parameters = Readonly<{
				div: HTMLDivElement;
				path: unknown;
				adUnits: Array<unknown>;
				auction: Auction;
				defaultFn: () => unknown;
			}>;
		}

		export namespace OnBeforeAuctionSetup {
			export type Parameters = Readonly<{
				auction: Auction;
				divAttribute: string;
			}>;
		}

		export namespace OnSlotAndUnit {
			export type Parameters = Readonly<{
				slot: googletag.Slot;
				unit: unknown;
				requestAuction: Auction;
				samePathIdx: number;
			}>;
		}

		export namespace OnAuctionInitDone {
			export type Parameters = Readonly<{ auction: Auction }>;
		}

		export namespace OnBeforeAdRequest {
			export type Parameters = Readonly<{
				auction: Auction;
				isTimeout: boolean | undefined;

				/** Custom extension by us that provides special targetings for the ad server, specific for SMD. */
				smdTargetingParameters?: ReadonlyArray<Parameters.SmdTargetingParameters>;
			}>;

			export namespace Parameters {
				export type SmdTargetingParameters = readonly [key: string, value: Array<string>];
			}
		}

		export namespace OnAuctionDone {
			export type Parameters = Readonly<{
				auction: Auction;
				isTimeout: boolean | undefined;
			}>;
		}

		export namespace OnAdUnitMatchingFailed {
			export type Parameters = Readonly<{
				div: HTMLDivElement;
				auction: Auction;
				path: unknown;
				defaultFn: () => unknown;
			}>;
		}

		export type PbjsCalls = Readonly<
			Partial<
				Pick<
					Record<string, never> & Pbjs,
					| 'requestBids'
					| 'addAdUnits'
					| 'removeAdUnit'
					| 'renderAd'
					| 'setConfig'
					| 'aliasBidder'
					| 'onEvent'
					| 'getBidResponsesForAdUnitCode'
					| 'getHighestCpmBids'
					| 'setTargetingForGPTAsync'
					| 'setTargetingForAst'
				>
			>
		>;

		export type GoogletagCalls = Readonly<
			Partial<
				Pick<
					googletag.Googletag & googletag.PubAdsService,
					| 'addEventListener'
					| 'removeEventListener'
					| 'getSlots'
					| 'defineSlot'
					| 'disableInitialLoad'
					| 'enableSingleRequest'
					| 'enableServices'
					| 'refresh'
				>
			>
		>;

		export type ApntagCalls = Readonly<
			Partial<
				Record<
					'defineTag' | 'loadTags' | 'showTag' | 'refresh' | 'setKeywords' | 'getTag' | 'modifyTag',
					unknown
				>
			>
		>;

		export type Auction = Readonly<{
			abTestLocalStorage: boolean;
			addUnitFromTemplate(...args: [unknown, unknown, unknown]): unknown;
			adsById: unknown;
			adservers: ReadonlyArray<unknown>;
			adUnits: ReadonlyArray<unknown>;
			allAdsIds: ReadonlyArray<string>;
			allowedDivIds: LoadPrebid.Parameters['allowedDivIds'];
			amazonAdUnitInstances: ReadonlyArray<unknown>;
			auctionId: string;
			bannerAdsIds: ReadonlyArray<string>;
			configId: string;
			data: unknown;
			divAttribute: string;
			events: unknown;
			finalAdUnits: ReadonlyArray<unknown>;
			finalizePbAdUnits(...args: [unknown]): unknown;
			get adserver(): unknown;
			get globalAdserverSettings(): unknown;
			getNonPbjsBidsForHba(): unknown;
			getVastXml(...args: [unknown]): unknown;
			hbaAuction: Auction.HbaAuction;
			hbaSiteId: string;
			init(...args: [unknown]): unknown;
			initInternal(): unknown;
			initRenderers(...args: [unknown]): unknown;
			instreamAdsIds: ReadonlyArray<string>;
			manageAdserver: boolean;
			name: string;
			noGpt: boolean;
			noSlotReload: boolean;
			onAuctionDone: LoadPrebid.Parameters['onAuctionDone'];
			onAuctionInitDone: LoadPrebid.Parameters['onAuctionInitDone'];
			onBeforeAdRequest: LoadPrebid.Parameters['onBeforeAdRequest'];
			onBeforeAuctionSetup: LoadPrebid.Parameters['onBeforeAuctionSetup'];
			onBeforeRequestBids(...args: [unknown]): unknown;
			onExternalSetBidFloor(): unknown;
			onHbaAuctionCreated(...args: [unknown]): unknown;
			onPrebidBidResponses(...args: [unknown]): unknown;
			onSlotAndUnit: LoadPrebid.Parameters['onSlotAndUnit'];
			pbConfig: unknown;
			pbjs: unknown;
			pbjsCall(...args: [unknown]): unknown;
			pbRequester: unknown;
			placementTypesById: unknown;
			removeUnitData(...args: [unknown]): unknown;
			renderBanner(...args: [unknown]): unknown;
			renderNative(...args: [unknown]): unknown;
			renderVideo(...args: [unknown]): unknown;
			run(...args: [unknown]): unknown;
			setDefFloorsIfNeeded(): unknown;
			settings: unknown;
			setUnitDatas(...args: [unknown]): unknown;
			setupManagedAdservers(...args: [unknown]): unknown;
			state: number;
			transparency: unknown;
			unitDatasByAds(...args: [unknown]): unknown;
			usedCodes: unknown;
			usedPbAdUnits: ReadonlyArray<unknown>;
			usedUnitDatas: ReadonlyArray<Auction.UsedUnitData>;
			userSync: unknown;
			videoSlots: undefined;
			viewport: unknown;
		}>;
	}

	export namespace Auction {
		export type UsedUnitData = Readonly<{
			get adserver(): unknown;
			adUnit: unknown;
			auction: LoadPrebid.Auction;
			code: string;
			finalPbAdUnits: ReadonlyArray<unknown>;
			pbAdUnit: unknown;
			renderDone: boolean;
			slot: googletag.Slot;
		}>;

		export type HbaAuction = Readonly<{
			auctionId: string;
			timestamp: number;
			auctionEnd: undefined;
			auctionStatus: string;
			adUnits: ReadonlyArray<unknown>;
			adUnitCodes: ReadonlyArray<unknown>;
			labels: undefined;
			bidderRequests: ReadonlyArray<unknown>;
			noBids: ReadonlyArray<unknown>;
			bidsReceived: ReadonlyArray<unknown>;
			bidsRejected: ReadonlyArray<unknown>;
			winningBids: ReadonlyArray<unknown>;
			timeout: number;
			metrics: unknown;
			seatNonBids: ReadonlyArray<unknown>;
			bidsById: unknown;
			id: string;
			mainDataSent: boolean;
			mainSendPending: boolean;
			extraSendPending: boolean;
			serverBids: ReadonlyArray<unknown>;
			pendingCommands: ReadonlyArray<unknown>;
			runningCommand: boolean;
			hbmAuction: unknown;
			allBids(): ReadonlyArray<HbaAuction.Bid>;
			msSinceStart(...args: [unknown]): unknown;
			isDone(): unknown;
			onHbmAdserverRequestSent(...args: [unknown]): unknown;
			sendUpdatesInternal(): unknown;
			sendUpdates(): unknown;
			getSystemParams(): unknown;
			getCustomParams(): unknown;
			reAdjustDuplicationPaths(): unknown;
			initAdUnitCodeMapping(): unknown;
			initBidOrder(): unknown;
			getSiteId(): unknown;
			sendMainDataInternal(): unknown;
			sendMainData(): unknown;
			serverBidArray(): unknown;
			sendCommand(...args: [unknown]): unknown;
		}>;

		export namespace HbaAuction {
			export type Bid = Readonly<{
				bidder: string;
				params: unknown;
				auctionId: string;
				floorData: unknown;
				ortb2Imp: unknown;
				mediaTypes: unknown;
				adUnitCode: string;
				transactionId: string;
				sizes: ReadonlyArray<unknown>;
				bidId: string;
				bidderRequestId: string;
				src: string;
				metrics: unknown;
				bidRequestsCount: number;
				bidderRequestsCount: number;
				bidderWinsCount: number;
				ortb2: unknown;
				bidIdx: number;
				auction: unknown;
				timedOut: boolean;
				noBid: boolean;
				bidWon: boolean;
				renderFailed: boolean;
				renderSuccess: boolean;
				unloadBeforeResponse: boolean;
				isVideo: boolean;
				isNative: boolean;
				adserverWon: boolean;
				responseMs: number;
				renderMs: number;
				bidderHandler: unknown;
				adjustedAdUnitCode: undefined;
				isDone(): unknown;
				getFlags(): unknown;
				getSspId(): unknown;
				getConvertedCpm(): unknown;
				finalAdUnitCode(): unknown;
				toServerObject(): unknown;
			}>;
		}
	}

	export type Window = { [Api.globalKey]?: Api | undefined };
}

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window extends Api.Window {}
}
